/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body {
  font-family: 'WorkSans-Regular';
  font-size: 16px;
}
ion-content {
  --background: transparent;
  --background: #ececec;
}
.modal-wrapper {
  background-color: #1e1f1e;
}
.ion-page {
  overflow: scroll;
}
.link {
    color: #014e37;
    cursor: pointer;
    outline: none;
    text-decoration: underline;
}

.swiper-pagination-bullet {
  opacity: 0.4 !important;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
}
.swiper-pagination {
  bottom: 40px !important;
}
